<template>
  <div class="box">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="curpoin">
      <el-breadcrumb-item><span class="curpoin" @click="goback">证书</span></el-breadcrumb-item>
      <el-breadcrumb-item>证书详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flexbet" style="margin:20px 0;min-height:600px;">
      <div class="left">
        <div class="title">{{ rowData.certificateName }}</div>
        <div v-if="rowData.certifyingAuthority" class="littleLabel">
          <svg-icon class="svgicon" icon-class="jigou"></svg-icon>
          {{ rowData.certifyingAuthority }}
        </div>
        <div v-if="rowData.targetAudience" class="littleLabel">
          <svg-icon class="svgicon" icon-class="qunzhong"></svg-icon>
          {{ rowData.targetAudience }}
        </div>
        <div v-if="rowData.certificateLevel" class="littleLabel">
          <svg-icon class="svgicon" icon-class="dengji"></svg-icon>
          {{ levelConver(rowData.certificateLevel) }}
        </div>
      </div>
      <div class="right">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="证书介绍" name="first">
            <div class="content" v-html="rowData.description"></div>
          </el-tab-pane>
          <el-tab-pane label="证书示例" name="second">
            <div class="imgDiv"><img :src="rowData.templateImage" alt=""></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      records: [],
      activeName: 'first'
    }
  },
  mounted() {

  },
  methods: {
    goback() {
      this.$emit('close')
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    levelConver(val) {
      switch (val) {
        case '1':
          return '初级'
        case '2':
          return '中级'
        case '3':
          return '高级'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box{
  max-width: 1280px;
      margin: 0 auto;
    padding: 30px 0;
}
// .title{
  //   width: 100%;
  // color: #252b3a;
  //   font-size: 24px;
  //   line-height: 30px;
  //   text-align: center;
  //   padding: 0px 0px 20px 0px;
  //   font-weight: 600;

// }
.title {
    font-size: 16px;
    font-weight: 500;
    color: #262a30;
    line-height: 26px;
    margin-bottom: 20px;
  font-family: Hiragino Sans GB,Helvetica Neue,Helvetica,STHeiTi,Microsoft Yahei,sans-serif;
}
.imgDiv{
  width: 100%;
  height: 100%;
      margin: 20px auto;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.curpoin{
  cursor: pointer;
}
.flexbet{
  display: flex;
  justify-content: space-between;
}
.left{
      background: #fbfeff;
  width: 20%;
      min-height: 100%;
  margin-right: 1.3%;
  padding: 15px;
  box-sizing: border-box;
}
.right{
  width: 78.7%;
      background: #fff;
    padding: 5px 20px;
  // padding: 15px;
  // box-sizing: border-box;
}
.littleLabel{
  font-size: 14px;
    line-height: 16px;
    color: #5c626b;
    margin: 15px 10px 15px 0;
}
</style>
