<!--
   User: Liu Yin
   Date: 2020/3/12
   Description:证书
 -->
<template>
  <article class="online-root">
    <div>
      <div class="flexcencen">
        <div class="container-course">
          <el-card shadow="always" class="label_box">
            <div>
              <div class="directe">
                <div class="field_direction">类型：</div>
                <ul v-for="(item, index) in firstLevel" :key="index">
                  <li :class=" item.dictValue === dictValue ? 'li_active' : '' " @click="queryType(item)">
                    {{ item.dictValue }}
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
          <div style="position: relative; margin: 0 auto 40px;">
            <el-input v-model="searchForm.keyWords" placeholder="搜索证书名称" class="inputwidth" @change="requestData">
              <i slot="suffix" class="el-icon-search"></i>
            </el-input>
          </div>

          <div class="container-right">
            <div v-if="records.length > 0" class="policyList">
              <ul>
                <li v-for="(item, index) in records" :key="index" @click="handleDetail(item)">
                  <article class="el-news-right-item">
                    <div class="el-news-list-card">
                      <img :src="getImg(item.templateImage)" @error="imgError">
                      <div>
                        <span class="news-item-title">
                          {{ item.certificateName }}
                        </span>
                        <p class="news-item-content" v-html="item.description"></p>
                        <!-- <div class="news-item-bottom">
                              <span class="bottom-item">{{ item.publishtime }}</span>
                            </div> -->
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
              <div style="text-align: center;">
                <el-pagination class="pageAction" :current-page.sync="currentPage" :page-size="size" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
              </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>

  </article>
</template>

<script>
import listMixin from '../../../../../utils/mixins/listMixin'
import backgroundImage from '../../../../../assets/images/none.png'

export default {
  components: {

  },
  mixins: [listMixin],
  data() {
    return {
      records: [],
      showDetail: false,
      firstLevel: [],
      searchForm: {
        category: ''
      },
      dictValue: '全部'
    }
  },
  mounted() {
    this.requestData()
    this.getDictObj()
  },
  methods: {
    queryType(row) {
      this.searchForm.category = row.dictCode
      this.dictValue = row.dictValue
      this.requestData()
    },
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.$get(this.urls.certificateList, this.searchForm, [
        this.currentPage,
        12
      ])
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.records = data.records
            this.total = data.total
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'certificate_categorization'
      })
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.firstLevel = data.certificate_categorization
            this.firstLevel.unshift({ dictValue: '全部', dictCode: '' })
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((e) => {
          console.error(e)
          this.toggleLoading(false)
        })
    },
    // refresh() {
    //   this.showDetail = false
    //   this.$emit('showDetail', false)
    // },
    // 查看详情
    handleDetail(item) {
      // this.rowData = item
      this.$emit('handleDetail', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.online-root {
  overflow-x: hidden;
  // width: 67%;

  margin: auto;
}
.label_box {
  // max-width: 1280px;
  border-radius: 8px;
  margin: 0px auto 20px;
}
.directe {
  ul {
    display: inline-block;
    li {
      box-sizing: border-box;
      margin: 0 8px 8px 0;
      padding: 6px 8px;
      color: #4e5969;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .li_active {
    color: #2c6efb;
    background-color: #e8f3ff;
  }
}

.field_direction {
  display: inline-block;
}
.directe {
  margin-bottom: 10px;
}

.inputwidth {
  position: relative;
  width: 43%;
  // position: absolute;
  top: 7px;
  right: 0px;
}
::v-deep .el-input__suffix {
  position: absolute;
  top: 12px !important;
}
.container-right {
  margin: 40px auto;
}
// .title {
//   font-size: 32px;
//   font-weight: 900;
//   width: 100%;
//   margin: 30px 0;
// }
.title {
  // font-size: 32px;
  // font-weight: 900;
  width: 100%;
  margin: 30px 0;
  line-height: 46px;
  font-size: 24px;
  font-weight: inherit;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
.news-item-title {
  color: #252b3a;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  max-width: 744px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.news-item-content {
  color: #575d6c;
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-height: 52px;
  width: 825px;
}
.news-item-bottom {
  color: #575d6c;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
}
.el-news-right-item {
  display: block;
  justify-content: space-between;
  transition: 0.2s;
  position: relative;
  height: inherit;
}
.el-news-list-card {
  height: 120px;
  padding: 15px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  img {
    width: 150px;
    height: 90px;
    margin-right: 36px;
  }
}
.policyList {
  ul {
    // width: 98%;
    margin: auto;
    // border-top: 1px solid #ededed;
  }
  // ul li:nth-child(odd) {
  //   background-color: #f7f7f7;
  // }
  // ul li:nth-child(even) {
  //   background-color: #fff;
  // }
  li {
    background: #fff;
    box-shadow: 0 0px 5px 0 rgb(0 0 0 / 10%);
    margin-top: 10px;
  }
}
.policyList {
  ul li:hover {
    transition: all 0.2s ease;
    transform: translateY(-3%);
    // box-shadow: 0 2px 20px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 12px 9px 0 rgb(0 0 0 / 20%);
  }
}
</style>
