<template>
  <article>
    <!-- <div class="index-top-banner-slide">轮播图</div> -->
    <div v-if="!showDetail">

      <swiperbanner class="banner" :dict-code="Banner.BANNER_TRAINING"></swiperbanner>
      <div class="banner-ad">
        <ul class="ad-wrapper">
          <li v-for="(item,index) in titleListData" :key="index" class="ad-item">
            <a href="#">
              <p class="item-caption"><span>{{ item.name_a }}</span></p>
              <p class="item-info" :title="item.name_b">{{ item.name_b }}</p>
            </a>
          </li>
        </ul>
      </div>
      <statics></statics>
      <el-row :gutter="10" class="flexcencen">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="title"><span>证书</span></div>
          <div class="box">
            <certificate @handleDetail="handleDetail"></certificate>
          </div>
        </el-col>
      </el-row>
    </div>
    <detail v-if="showDetail" :row-data="rowData" @close="refresh"></detail>
  </article>
</template>

<script>
import listMixin from '../../../../utils/mixins/listMixin'
import statics from './components/statics'
import certificate from './components/certificate'
import detail from './components/detail'
import { Banner } from '../../../../utils/dict'
import Swiperbanner from '../../../../components/swiperbanner.vue'

export default {
  components: {
    statics,
    Swiperbanner,
    certificate,
    detail
  },
  mixins: [listMixin],
  data() {
    return {
      Banner,
      // banner_bottom_text
      titleListData: [
        { name_a: '高研班', name_b: '提炼1000+个企业真实案例，提升管理认知，拓宽管理视野' },
        { name_a: '华为云开发者认证', name_b: '聚焦华为云上应用设计、构建和运维打造的系统化认证' },
        { name_a: '工程技术专业培训', name_b: '提升企业CTO技术管理人员和技术骨干关于创新技术应用的能力' }
      ],
      showDetail: false,
      rowData: {}
    }
  },
  created() {
    document.body.scrollTop = 0
  },
  methods: {
    handleDetail(val) {
      this.rowData = val
      this.showDetail = true
      this.setScrollPosition()
    },
    refresh() {
      this.showDetail = false
      this.scrollToPosition()
    }
  }
}
</script>
<style lang="scss" scoped>
.base-with {
  width: 1280px;
  margin: 0 auto;
}
.index-top-banner-slide {
  height: 430px;
  width: 100%;
}
.banner-ad {
  display: flex;
  justify-content: center;
  background: #fdfdfd;
}
.ad-wrapper {
  width: 1280px;
}
.ad-item {
  width: 33.3%;
  display: inline-block;
  text-align: center;
  padding: 24px 0;
  overflow: hidden;
  cursor: pointer;
  .item-caption {
    position: relative;
    font-size: 0;
    color: #252b3a;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 8px;
    span {
      vertical-align: middle;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      position: relative;
      text-align: center;
    }
  }
  .item-info {
    font-size: 14px;
    color: #575d6c;
    letter-spacing: 0;
    text-align: center;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.ad-item:nth-child(2) a {
  display: block;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}
.ad-item {
  .item-caption {
    span:hover {
      color: #409eff;
    }
  }
}
.flexcencen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  max-width: 1280px;
  margin: 30px auto;
}
.title {
  // font-size: 32px;
  // font-weight: 900;
  width: 100%;
  line-height: 46px;
  font-size: 24px;
  font-weight: inherit;
  color: #252b3a;
  font-family: Hiragino Sans GB, Helvetica Neue, Helvetica, STHeiTi,
    Microsoft Yahei, sans-serif;
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.title span {
  position: relative;
}

.title span:after {
  position: absolute;
  content: " ";
  left: -16px;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: linear-gradient(135deg, #2791ff 0, #03a7ff 98%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
</style>
